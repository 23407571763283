<!--
 * @Author: your name
 * @Date: 2020-08-27 14:43:37
 * @LastEditTime: 2021-07-15 11:33:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT_Web_test\src\views\EBCHome\statisticalAnalysis\flowAnalysisPop.vue
-->
<template>
    <div class="flowAnalysisPop">
        <h4>
            <p>流向分析</p>
            <div class="light-beam"></div>
        </h4>
        <div class="select-con">
            <span>原材料规格：</span>
            <el-select v-model="value" placeholder="请选择" @change="selectSpaceCode">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
            <el-radio-group v-model="filterType" @change="changeFilterType">
                <el-radio :label="0">
                    按企业
                </el-radio>
                <el-radio :label="1">
                    按场站
                </el-radio>
            </el-radio-group>
        </div>
        <div class="chart-con">
            <div class="chart-item">
                <h3>
                    <!-- <span class="split"></span> -->
                    <span class="el_left">各{{ filterType==0?'企业':'场站' }}供应总量对比</span>
                    <strong class="el_right">单位(吨)</strong>
                </h3>
                <div id="barChart"></div>
            </div>
            <div class="chart-item">
                <h3>
                    <!-- <span class="split"></span> -->
                    <span class="el_left">供货变化曲线</span>
                    <strong class="el_right">单位(吨)</strong>
                </h3>
                <div id="lineChart"></div>
            </div>
        </div>
        <div class="tabledata">
            <el-table
                :data="tableList"
                ref="multipleTable"
                height="100%"
            >
                <el-table-column
                    prop="name"
                    :label="filterType==0?'企业名称':'场站名称'"
                >
                </el-table-column>
                <template v-for="(item,index) in chartData.DateList">
                    <el-table-column
                        :key="index"
                        :label="item"
                    >
                        <template slot-scope="scope">
                            {{ chartData.TenantDateAreaList[scope.$index][index] }}
                        </template>
                    </el-table-column>
                </template>
                <el-table-column
                    prop="total"
                    label="合计"
                >
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import bg_blue from '@/assets/images/gk/bg5.png';
export default {
    components: {},
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        return {
            // 数据查询类型 0：按企业，1按场站
            filterType: 0,
            // 货源地id
            sourceId: '',
            // 原材料编号
            typeCode: '',
            // 开始时间
            beginTime: '',
            // 结束时间
            endTime: '',
            // 规格型号
            specCode: '',
            options: [{
                value: '',
                label: '全部',
            } ],
            value: '',
            chartData: {},
            colors: ['#13BD63', '#40B6FF', '#DCC129', '#FA8C47', '#8D43C1'],
            // 表格数据列表
            tableList: [],
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        this.sourceId = this.extr.sourceId;
        this.typeCode = this.extr.typeCode;
        this.beginTime = this.extr.beginTime;
        this.endTime = this.extr.endTime;
        this.handleTypeCodes();
        this.handleStationStat();
    },
    methods: {
        // 改变过滤类型
        changeFilterType() {
            this.handleStationStat();
        },
        // 规格型号筛选
        selectSpaceCode(val) {
            this.specCode = val;
            this.handleStationStat();
        },
        // 处理规格型号数据
        async handleTypeCodes() {
            const res = await this.getTypeCodes();
            if (res) {
                res.map(item => {
                    this.options.push(
                        {
                            value: item.code,
                            label: item.name,
                        },
                    );
                });
            }
        },
        // 处理统计图数据
        async handleStationStat() {
            const tableList = [];
            let res = null;
            if (this.filterType === 0) {
                res = await this.getCompanyStat();
            } else {
                res = await this.getStationStat();
            }
            if (res) {
                res.DateList = res.DateList.map(item => {
                    return item.substr(5);
                });
                this.chartData = res;
                this.initBarChart();
                const data = [];
                res.TenantDateAreaList.map((item, index) => {
                    const json = {
                        name: res.TenantNameList[index],
                        type: 'line',
                        stack: '总量',
                        itemStyle: {
                            normal: {
                                color: this.colors[index],
                                lineStyle: {
                                    color: this.colors[index],
                                },
                            },
                        },
                        label: {
                            show: true,
                            position: 'bottom',
                            fontSize: '12',
                            padding: 0,
                            color: this.colors[index],
                        },
                        data: res.TenantDateAreaList[index],
                    };
                    data.push(json);
                    // 生成表格数据
                    tableList.push({
                        name: res.TenantNameList[index],
                        total: res.TenantTotalList[index],
                    });
                });
                this.initLineChart(data);
            }
            this.tableList = tableList;
        },
        // 获取规格型号
        getTypeCodes() {
            const path = '/interfaceApi/procurement/material/group/type/spec/get/type_code?type_code=' + this.typeCode;
            return this.$axios.$get(path, { defEx: true });
        },
        // 获取企业纬度按月统计
        getCompanyStat() {
            const path = '/interfaceApi/procurement/source/get_source_supply_month?beginTime='
            + this.beginTime + '&endTime=' + this.endTime + '&typeCode=' + this.typeCode
            + '&sourceId=' + this.sourceId + '&specCode=' + this.specCode;
            return this.$axios.$get(path, { defEx: true });
        },
        // 获取场站按月统计
        getStationStat() {
            const path = '/interfaceApi/procurement/source/get_source_supply_month_station?beginTime='
            + this.beginTime + '&endTime=' + this.endTime + '&typeCode=' + this.typeCode
            + '&sourceId=' + this.sourceId + '&specCode=' + this.specCode;
            return this.$axios.$get(path, { defEx: true });
        },
        // 各企业供应总量对比
        initBarChart() {
            const myChart = this.$echarts.init(document.getElementById('barChart'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                },
                grid: {
                    top: 40,
                    left: 60,
                    bottom: 50,
                    right: 20,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.chartData.TenantNameList,
                        axisPointer: {
                            type: 'shadow',
                        },
                        // 设置间隔
                        axisLabel: {
                            interval: 0,
                            fontSize: 12, // 字体大小
                            rotate: 50,
                        },
                        // 设置边框样式
                        axisLine: {
                            lineStyle: {
                                color: 'rgba(255,255,255,0.3)',
                            },
                        },
                        splitLine: { show: false },
                        axisTick: { show: false },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        // 设置边框样式
                        axisLine: {
                            lineStyle: {
                                color: 'rgba(255,255,255,0.3)',
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                type: 'dashed',
                                color: 'rgba(255,255,255,0.3)',
                            },
                        },
                        axisTick: { show: false },
                    },
                ],
                series: [
                    {
                        name: '供应总量',
                        type: 'bar',
                        itemStyle: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: 'rgba(35,210,253,1)', // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: 'rgba(45,122,232,1)', // 100% 处的颜色
                            }], false),
                        },
                        label: {
                            show: true,
                            position: 'top',
                            fontSize: '12',
                            color: 'rgba(45,122,232,1)',
                            lineHeight: 22,
                            padding: [0, 10, 4, 10],
                            backgroundColor: {
                                image: bg_blue,
                            },
                        },
                        barWidth: 30,
                        data: this.chartData.TenantTotalList,
                    },
                ],
            };
            myChart.setOption(option, true);
        },
        // 各企业供应总量对比
        initLineChart(data) {
            const myChart = this.$echarts.init(document.getElementById('lineChart'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                },
                legend: {
                    top: 15,
                    right: 0,
                    data: this.chartData.TenantNameList,
                    textStyle: {
                        color: 'rgba(255,255,255,0.8)',
                    },
                },
                grid: {
                    top: 60,
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.chartData.DateList,
                    axisPointer: {
                        type: 'shadow',
                    },
                    // 设置间隔
                    axisLabel: {
                        interval: 0,
                        fontSize: 12, // 字体大小
                        // rotate: 30,
                    },
                    // 设置边框样式
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(255,255,255,0.3)',
                        },
                    },
                    splitLine: { show: false },
                    axisTick: { show: false },
                },
                yAxis: {
                    type: 'value',
                    // 设置间隔
                    axisLabel: {
                        formatter: '{value}',
                        interval: 0,
                        fontSize: 12, // 字体大小
                    },
                    // 设置边框样式
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(255,255,255,0.3)',
                        },
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: 'rgba(255,255,255,0.3)',
                        },
                    },
                    axisTick: { show: false },
                },
                series: data,
            };
            myChart.setOption(option, true);
        },
    },
};
</script>
<style lang="stylus">
.flowAnalysisPop
    height 100%
    background #032041
    box-shadow 1px 1px 6px 0px rgba(0,0,0,0.8)
    padding .2rem
    font-size .16rem
    ::-webkit-scrollbar-thumb
        border-radius: 0.1rem;
        -webkit-box-shadow: inset 0 0 0.05rem rgb(0 0 0 / 10%);
        background: linear-gradient(270deg, #4C658A 0%, #57749F 100%);
    ::-webkit-scrollbar
        width 0.08rem
        background: #000;
        border-radius: 3px;
        opacity: 0.5;
    h4
        font-size .18rem
        color #fff
        line-height .27rem
        background linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(108,151,216,1) 100%)
        -webkit-background-clip text
        -webkit-text-fill-color transparent
        text-align center
        position relative
        .light-beam
            position absolute
            top .1rem
            width 100%
            background url(./../images/line.png) no-repeat
            height .47rem
            background-size 100% 100%
    .select-con
        color #fff
        margin-top .3rem
        display: flex;
        align-items: center;
        .el-input__inner
            width 1.2rem
            background-color #0C274F
            border 1px solid #32579F
            color #fff
        .el-radio-group
            margin-left 0.25rem
            .el-radio__inner
                border 1px solid rgba(255,255,255,0.6)
                background transparent
            .el-radio__input.is-checked+.el-radio__label
                color #FFFFFF
    .chart-con
        display flex
        margin-top .2rem
        .chart-item
            flex 1
            margin-right 0.4rem
            &:nth-of-type(2)
                margin-right 0
            h3
                text-align left
                font-size 0.16rem
                line-height .16rem
                color rgba(255,255,255,0.8)
                padding-left 0.1rem
                border-left 0.06rem solid rgba(255,255,255,1)
                overflow hidden
                strong
                    font-weight 400
            #barChart
                width 100%
                height 4.1rem
            #lineChart
                width 100%
                height 4.1rem
    .tabledata
        margin-top 0.1rem;
        height: calc(100% - 5.5rem);
        .el-table
            border 1px solid #000
            background-color #032041
            font-size .14rem
            &:before
                display none
            th,tr
                background-color #032041
            td,th
                border-bottom 1px solid #000
                border-right 1px solid #000
                text-align center
                padding .08rem 0
            th
                color #FFFFFF
                font-weight normal
            td
                color #20B0FB
                .view
                    color #C4FE72
                    cursor pointer
        .el-table--border
            td,th
                border-right 1px solid #000
            &::after
                background-color #000
            &::before
                background-color #000
        .el-table--enable-row-hover
            .el-table__body
                tr
                    &:hover
                        td
                            background #0D53A1
</style>
